import { useRemoteConfig } from 'promova-student/components/modules/RemoteConfig/RemoteConfigProvider'
import { useMemo } from 'react'

import parseRemoteConfigValue from '../parseRemoteConfigValue'

const useSplitValue = (key: string) => {
  const { remoteConfig } = useRemoteConfig()

  const { isUseSplit, isRemoteConfigFetched } = useMemo(() => {
    const isFetched = remoteConfig?.isFetched
    const splitValue = parseRemoteConfigValue(remoteConfig[key])

    if (isFetched) {
      return {
        isUseSplit: splitValue,
        isRemoteConfigFetched: isFetched,
      }
    }
    return {
      isUseSplit: splitValue,
      isRemoteConfigFetched: false,
    }
  }, [remoteConfig, key])

  return { isUseSplit, isRemoteConfigFetched }
}

export default useSplitValue

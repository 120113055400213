import { FC, useState } from 'react'

import Avatar from '@promova/ui/elements/Avatar/Avatar'

import { useAppSelector } from '@reduxStore/hooks'

import { LANDINGS_DROPDOWN_NAVIGATION } from '@constants_folder/navigationScheme'

import { sendAnalytics } from '@utils/analytics'

import DropdownNavigation from '@templates/NavigationLayout/DropdownNavigation/DropdownNavigation'

import styles from './dropdown_menu.module.scss'

const defaultAvatar = '/images/Avatar_placeholder.svg'

const DropdownMenu: FC = () => {
  const userProfile = useAppSelector((state) => state.profile.userProfile)

  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropDown = (e: React.SyntheticEvent<HTMLElement>) => {
    e?.stopPropagation()
    const eventName = e?.currentTarget?.dataset?.event

    if (eventName) {
      sendAnalytics({
        eventName,
        data: {
          place: 'burger-menu',
        },
      })
    }

    setShowDropdown(!showDropdown)
  }

  const userPhoto = userProfile?.avatar?.url || defaultAvatar

  return (
    <div className={styles.container}>
      <button type="button" onClick={toggleDropDown}>
        <Avatar url={userPhoto} size={44} />
      </button>

      <DropdownNavigation
        active={showDropdown}
        onItemClick={toggleDropDown}
        links={LANDINGS_DROPDOWN_NAVIGATION}
      />
    </div>
  )
}

export default DropdownMenu

const parseRemoteConfigValue = <T extends string>(value: T): boolean | T => {
  let splitValue: boolean | T

  try {
    if (typeof JSON.parse(value) === 'object') {
      const parsedValue = JSON.parse(value)

      splitValue = {
        ...parsedValue,
        value:
          parsedValue?.value === 'true' || parsedValue?.value === 'false'
            ? JSON.parse(parsedValue.value)
            : parsedValue.value,
      }
    } else if (value === 'true' || value === 'false') {
      splitValue = JSON.parse(value)
    } else {
      splitValue = value
    }
  } catch (e) {
    splitValue = value
  }
  return splitValue
}

export default parseRemoteConfigValue

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import Icon from '@promova/ui/components/Icon/Icon'

import { useAppSelector } from '@reduxStore/hooks'

import { StrapiCtaBannerList, storeLinks } from '@constants'
import { CTA_BANNER_MOBILE_ONLY_ALREADY_SHOWN } from '@constants_folder/storageKeys'

import { CtaBannerForArticleAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'

import { sendAnalytics } from '@utils/analytics'
import { createLinkWithUtms } from '@utils/createLinkWithUtms'
import useBrowserStorage from '@utils/customHooks/useBrowserStorage'
import useLandingInfo from '@utils/customHooks/useLandingInfo'
import useWebViewMode from '@utils/customHooks/useWebViewMode'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import promovaIcon from '@images/promova_logos/logo_icon.png'

import Button from '@elements/ButtonV2/Button'

import styles from './cta_banner_mobile_only.module.scss'

const defaultValues = {
  bannerTitle: t`Try our app for better learning`,
  buttonLink: storeLinks.universal,
  buttonText: t`Install Now`,
  buttonEventText: 'Install Now',
  buttonEventType: 'download',
}

const CtaBannerMobileOnly: FC = () => {
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const { locale } = useRouter()
  const [isOpen, setIsOpen] = useState(true)
  const [isScrolling, setIsScrolling] = useState(false)
  const [hasBeenShown, setHasBeenShown] = useBrowserStorage({
    storageType: 'sessionStorage',
    key: CTA_BANNER_MOBILE_ONLY_ALREADY_SHOWN,
    initialValue: false,
    timeToLive: null,
  })
  const { isWebViewMode } = useWebViewMode()
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)',
  })

  const shouldFetchBannerData = isMobile && !isWebViewMode

  const shouldDisplayBanner =
    isMobile && !isWebViewMode && !hasBeenShown && isOpen

  // memoizing the Icon component to avoid re-rendering on scroll, which causes the Icon blinking
  const closeIcon = useMemo(() => <Icon icon="close" size={18} />, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    setHasBeenShown(true)
  }, [setHasBeenShown])

  const {
    data: banner,
    isLoading,
    isError,
  } = useCtaBannerListQuery<CtaBannerForArticleAttributes>({
    locale,
    enabled: shouldFetchBannerData,
    slug: StrapiCtaBannerList.CTA_BANNER_MOBILE_ONLY,
  })

  const handleSendOnbEvent = useCallback(() => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: 'banner',
        text: banner?.buttonEventText || defaultValues.buttonEventText,
        type: banner?.buttonEventType || defaultValues.buttonEventType,
      },
    })
  }, [
    amplitudeInited,
    LANDING_PATH,
    banner?.buttonEventText,
    banner?.buttonEventType,
  ])

  useEffect(() => {
    const onScroll = () => {
      if (!isScrolling && window.scrollY > 0) setIsScrolling(true)
      if (window.scrollY === 0) setIsScrolling(false)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [isScrolling])

  const bannerClassNames = classNames(styles.cta_banner_mobile_only, {
    [styles.banner_scrolling]: isScrolling,
  })

  const isDataStatusProblematic = isLoading || isError || !banner
  const isInvalidPopupState = banner?.isDisabled || !shouldDisplayBanner

  const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: banner?.urlsToInclude || [],
    excludedUrls: banner?.urlsToExclude || [],
  })

  if (isDataStatusProblematic || isInvalidPopupState || !isCTAVisibleBasedOnUrl)
    return null

  return (
    <aside className={bannerClassNames}>
      <div className={styles.banner_wrapper}>
        <figure className={styles.icon_container}>
          <Image src={promovaIcon} alt="promova-icon.png" layout="fill" />
        </figure>
        <div className={styles.title}>
          {banner?.bannerTitle || i18n._(defaultValues.bannerTitle)}
        </div>
        <Button
          className={styles.btn_main}
          type="filled"
          link={createLinkWithUtms(
            banner?.button?.href || defaultValues.buttonLink
          )}
          onClick={handleSendOnbEvent}
          isExternal={banner?.button?.isExternal ?? true}
        >
          {banner?.button?.label || i18n._(defaultValues.buttonText)}
        </Button>
      </div>

      <button className={styles.btn_close} type="button" onClick={handleClose}>
        {closeIcon}
      </button>
    </aside>
  )
}

export default CtaBannerMobileOnly

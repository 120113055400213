import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'

import Icon from '@promova/ui/components/Icon/Icon'

import { useAppSelector } from '@reduxStore/hooks'

import { StrapiCtaBannerList } from '@constants'
import { CTA_TUTORING_POPUP_ALREADY_SHOWN } from '@constants_folder/storageKeys'

import { CtaBannerForArticleAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'
import useBreakpoints from '@hooks/useBreakpoints'

import { sendAnalytics } from '@utils/analytics'
import useBrowserStorage from '@utils/customHooks/useBrowserStorage'
import useLandingInfo from '@utils/customHooks/useLandingInfo'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import Button from '@elements/ButtonV2/Button'

import styles from './cta_tutoring_popup.module.scss'
import tutorImage from './images/tutor.png'

const defaultValues = {
  bannerTitle: t`Choose a tutor whose interests match yours`,
  buttonLink: '/tutoring/short-onboarding',
  buttonText: t`Find your tutor`,
  buttonEventText: 'Find your tutor',
  buttonEventType: 'intro tutors',
}

const THREE_DAYS_IN_SECONDS = 3 * 86400

const CtaTutoringPopup: FC = () => {
  const { locale } = useRouter()
  const { lg: isLgScreen } = useBreakpoints()
  const {
    data: popup,
    isLoading,
    isError,
  } = useCtaBannerListQuery<CtaBannerForArticleAttributes>({
    locale,
    enabled: isLgScreen,
    slug: StrapiCtaBannerList.CTA_TUTORING_POPUP,
  })
  const popupRef = useRef<HTMLElement | null>(null)

  const [isOpen, setIsOpen] = useState(true)
  const [hasBeenShown, setHasBeenShown] = useBrowserStorage({
    storageType: 'localStorage',
    key: CTA_TUTORING_POPUP_ALREADY_SHOWN,
    initialValue: false,
    timeToLive: THREE_DAYS_IN_SECONDS,
  })

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const handleSendOnbEvent = () => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: 'banner',
        text: popup?.buttonEventText || defaultValues.buttonEventText,
        type: popup?.buttonEventType || defaultValues.buttonEventType,
      },
    })
  }

  const handleClose = () => {
    setIsOpen(false)
    setHasBeenShown(true)
  }

  useEffect(() => {
    if (!popup || !isLgScreen || !popupRef.current) return undefined

    const popupEl = popupRef.current
    const topIndent = 130
    const currentPopupPos = popupEl.getBoundingClientRect().top + window.scrollY

    const onScroll = () => {
      if (window.scrollY + topIndent > currentPopupPos) {
        popupEl.style.position = 'fixed'
        popupEl.style.top = `${topIndent}px`
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [popup, isLgScreen])

  const isDataStatusProblematic = isLoading || isError || !popup
  const isInvalidPopupState = popup?.isDisabled || hasBeenShown || !isOpen
  const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: popup?.urlsToInclude || [],
    excludedUrls: popup?.urlsToExclude || [],
  })

  if (
    isInvalidPopupState ||
    !isLgScreen ||
    isDataStatusProblematic ||
    !isCTAVisibleBasedOnUrl
  ) {
    return null
  }

  return (
    <aside className={styles.cta_tutoring_popup} ref={popupRef}>
      <div className={styles.close_btn}>
        <button
          type="button"
          className={styles.icon_wrapper}
          onClick={handleClose}
        >
          <Icon icon="close" scaled />
        </button>
      </div>

      <div className={styles.top_section}>
        <figure className={styles.image_container}>
          <Image src={tutorImage} alt="tutor.png" layout="fill" />
        </figure>
        <div className={styles.title}>
          {popup?.bannerTitle || i18n._(defaultValues.bannerTitle)}
        </div>
      </div>

      <div className={styles.bottom_section}>
        <Button
          className={styles.button}
          link={popup?.button?.href || defaultValues.buttonLink}
          isExternal={popup?.button?.isExternal ?? false}
          onClick={handleSendOnbEvent}
        >
          {popup?.button?.label || i18n._(defaultValues.buttonText)}
        </Button>
      </div>
    </aside>
  )
}

export default CtaTutoringPopup

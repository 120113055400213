import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'

import sortByOrder from '@promova/utils/sortByOrder'

import { FREE_FOR_UKRAINE_DEFAULT_ROUTE } from '@constants'

import { LinkType, LinksListType } from '@_types/landings'

import { createLinkWithUtms } from '@utils/createLinkWithUtms'

import StrapiImage from '@elements/StrapiImage/StrapiImage'

import styles from './buttons_list.module.scss'

type Props = {
  buttonsList: LinksListType
}

const ButtonsList: FC<Props> = ({ buttonsList }) => {
  const { pathname } = useRouter()

  const sortedButtons: LinkType[] = useMemo(() => {
    let { links } = buttonsList
    if (pathname === FREE_FOR_UKRAINE_DEFAULT_ROUTE) {
      links = links.filter((link) => link.label !== 'AppGallery')
    }
    return sortByOrder(links)
  }, [buttonsList, pathname])

  return (
    <div className={styles.column}>
      <div className={styles.title}>{buttonsList.title}</div>
      <div className={styles.wrapper}>
        {sortedButtons.map((link) => (
          <Link
            key={link.id}
            href={link.href ? createLinkWithUtms(link.href) : '#'}
            passHref
          >
            <a
              className={styles.button}
              target={link.target || '_self'}
              rel={link.rel || ''}
            >
              <figure className={styles.store_icon}>
                <StrapiImage image={link.icon} />
              </figure>
              <div>
                <div className={styles.text_small}>{link.text}</div>
                <div className={styles.text_large}>{link.label}</div>
              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default ButtonsList

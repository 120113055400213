import { Trans } from '@lingui/macro'
import { signOut } from 'firebase/auth'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'

import Avatar from '@promova/ui/elements/Avatar/Avatar'
import SignOutIcon from '@promova/ui/icons/Sign_out.svg'

import { useAppSelector } from '@reduxStore/hooks'

import { PagesRoutes, ROUTES_DISABLE_LOCALES } from '@constants_folder/routes'

import { AuthContext } from '@modules/Auth/AuthProvider'

import FreeUkraineButton from '@elements/FreeUkraineButton/FreeUkraineButton'

import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import styles from './navigation_actions.module.scss'

const defaultAvatar = '/images/Avatar_placeholder.svg'

interface Props {
  onCloseSidebar: () => void
  availableLocales?: string[]
  showFreeUkraineButton?: boolean
  showLanguageSwitch?: boolean
}

const NavigationActions: FC<Props> = ({
  onCloseSidebar,
  availableLocales,
  showLanguageSwitch = true,
  showFreeUkraineButton = true,
}) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1100px)',
  })
  const router = useRouter()

  const { user, auth } = useContext(AuthContext)

  const userProfile = useAppSelector((state) => state.profile.userProfile)
  const userPhoto = userProfile?.avatar?.url || defaultAvatar
  const isShowLanguageSwitch =
    !ROUTES_DISABLE_LOCALES.includes(router?.route) && showLanguageSwitch

  const handleSignOut = () => {
    if (auth) {
      signOut(auth)
    }
  }

  return (
    <div>
      {user ? (
        <ul>
          <li className={styles.action_item}>
            <Link href="/">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={onCloseSidebar} className={styles.flex_btn}>
                <span className={styles.icon_box}>
                  <Avatar url={userPhoto} size={24} />
                </span>
                <Trans>Back to Studying</Trans>
              </a>
            </Link>
          </li>
          <li className={styles.action_item}>
            <button
              type="button"
              onClick={handleSignOut}
              className={styles.logout}
            >
              <span className={styles.icon_box}>
                <SignOutIcon className={styles.sign_out_icon} />
              </span>
              <Trans>Sign out</Trans>
            </button>
          </li>
        </ul>
      ) : (
        <>
          {!isDesktop && showFreeUkraineButton && (
            <div className={styles.free_ukraine_box}>
              <FreeUkraineButton />
            </div>
          )}
          <div className={styles.container}>
            {isDesktop && showFreeUkraineButton && <FreeUkraineButton />}

            <Link href={PagesRoutes.signIn}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a className={styles.login} onClick={onCloseSidebar}>
                <Trans>Log in</Trans>
              </a>
            </Link>
            {isShowLanguageSwitch && (
              <LanguageSwitch
                popupPosition={isDesktop ? 'bottom' : 'top'}
                availableLocales={availableLocales}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default NavigationActions

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import Logo from '@promova/ui/icons/promova_logo_bold.svg'

import { useAppSelector } from '@reduxStore/hooks'

import { StrapiCtaBannerList } from '@constants'

import { CtaBannerForArticleAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'

import { sendAnalytics } from '@utils/analytics'
import useLandingInfo from '@utils/customHooks/useLandingInfo'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import Button from '@elements/ButtonV2/Button'

import styles from './cta_tutoring_banner.module.scss'
import chat from './images/bubble_chat.png'
import lecture from './images/lecturere.png'

const defaultValues = {
  bannerTitle: t`Choose a tutor whose interests match yours`,
  buttonLink: '/tutoring/short-onboarding',
  buttonText: t`Find your tutor`,
  buttonEventText: 'Find your tutor',
  buttonEventType: 'intro tutors',
}

const CtaTutoringBanner: FC = () => {
  const { locale } = useRouter()
  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const [isScrolling, setIsScrolling] = useState(false)

  const {
    data: banner,
    isLoading,
    isError,
  } = useCtaBannerListQuery<CtaBannerForArticleAttributes>({
    locale,
    slug: StrapiCtaBannerList.CTA_TUTORING_BANNER,
  })

  const handleSendOnbEvent = () => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: 'banner',
        text: banner?.buttonEventText || defaultValues.buttonEventText,
        type: banner?.buttonEventType || defaultValues.buttonEventType,
      },
    })
  }

  useEffect(() => {
    const onScroll = () => {
      if (!isScrolling && window.scrollY > 350) {
        setIsScrolling(true)
      }
      if (window.scrollY === 0) {
        setIsScrolling(false)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [isScrolling])

  const bannerClassNames = classNames(styles.cta_tutoring_banner, {
    [styles.banner_scrolling]: isScrolling,
  })

  const isDataStatusProblematic = !banner || isLoading || isError

  const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: banner?.urlsToInclude || [],
    excludedUrls: banner?.urlsToExclude || [],
  })

  if (isDataStatusProblematic || !isCTAVisibleBasedOnUrl || banner?.isDisabled)
    return null

  return (
    <aside className={bannerClassNames}>
      <Link href="/">
        <Logo className={styles.logo} />
      </Link>

      <figure className={styles.image_chat}>
        <Image src={chat} alt="bubble_chat.png" layout="fill" />
      </figure>

      <div className={styles.title}>
        {banner?.bannerTitle || i18n._(defaultValues.bannerTitle)}
      </div>

      <figure className={styles.image_lecture}>
        <Image src={lecture} alt="lecturere.png" layout="fill" />
      </figure>

      <Button
        className={styles.button}
        type="filled"
        link={banner?.button?.href || defaultValues.buttonLink}
        isExternal={banner?.button?.isExternal ?? false}
        onClick={handleSendOnbEvent}
      >
        {banner?.button?.label || i18n._(defaultValues.buttonText)}
      </Button>
    </aside>
  )
}

export default CtaTutoringBanner

import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect, useState } from 'react'

import { useAppSelector } from '@reduxStore/hooks'

import { COURSES_ONBOARDING, StrapiCtaBannerList } from '@constants'

import { CtaAppBannerAttributes } from '@_types/strapi/singleTypes'

import { useCtaBannerListQuery } from '@hooks/dataFetching/CTA/useCtaBannerListQuery'

import { sendAnalytics } from '@utils/analytics'
import useLandingInfo from '@utils/customHooks/useLandingInfo'
import { shouldRenderCTAByIncludedExcludedUrls } from '@utils/shouldRenderCTAByIncludedExcludedUrls'

import FlagsSection from './FlagsSection/FlagSection'
import styles from './cta_app_banner.module.scss'

const defaultValues = {
  bannerTitle: t`Ready to learn?`,
  bannerText: t`Pick a language to get started!`,
  buttonLink: `/funnels/${COURSES_ONBOARDING}`,
  buttonEventText: 'Ready to learn?',
  buttonEventType: 'onboarding',
}

const CtaAppBanner: FC = () => {
  const { locale } = useRouter()

  const {
    data: banner,
    isLoading,
    isError,
  } = useCtaBannerListQuery<CtaAppBannerAttributes>({
    locale,
    slug: StrapiCtaBannerList.APP_BANNER,
  })

  const amplitudeInited = useAppSelector(
    (state) => state?.amplitude?.amplitudeInited
  )
  const { LANDING_PATH } = useLandingInfo()

  const [isScrolling, setIsScrolling] = useState(false)

  const handleSendOnbEvent = useCallback(() => {
    if (!amplitudeInited) return

    sendAnalytics({
      eventName: 'landing_clicked_cta',
      data: {
        landing_name: 'none',
        page_path: LANDING_PATH,
        place: LANDING_PATH.split('/').at(1),
        text: banner?.buttonEventText || defaultValues.buttonEventText,
        type: banner?.buttonEventType || defaultValues.buttonEventType,
      },
    })
  }, [
    amplitudeInited,
    LANDING_PATH,
    banner?.buttonEventText,
    banner?.buttonEventType,
  ])

  useEffect(() => {
    const onScroll = () => {
      if (!isScrolling && window.scrollY > 20) setIsScrolling(true)
      if (window.scrollY === 0) setIsScrolling(false)
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [isScrolling])

  const bannerClassNames = classNames(styles.cta_app_banner, {
    [styles.banner_scrolling]: isScrolling,
  })

  const isDataStatusProblematic = isLoading || isError || !banner
  const isCTAVisibleBasedOnUrl = shouldRenderCTAByIncludedExcludedUrls({
    currentUrl: LANDING_PATH,
    includedUrls: banner?.urlsToInclude || [],
    excludedUrls: banner?.urlsToExclude || [],
  })

  if (!isCTAVisibleBasedOnUrl || isDataStatusProblematic || banner?.isDisabled)
    return null

  return (
    <aside className={bannerClassNames}>
      <div className={styles.description}>
        <div className={styles.title}>
          {banner?.bannerTitle || i18n._(defaultValues.bannerTitle)}
        </div>
        <div className={styles.text}>
          {banner?.bannerText || i18n._(defaultValues.bannerText)}
        </div>
      </div>

      <div className={styles.apps}>
        <FlagsSection
          link={banner?.link?.href || defaultValues.buttonLink}
          handleLinkClick={handleSendOnbEvent}
          isScrolling={isScrolling}
        />
      </div>
    </aside>
  )
}

export default CtaAppBanner
